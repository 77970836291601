// src/components/ResponsiveGrid.js
import React from 'react'
import { Link } from 'gatsby'
import styled from '@emotion/styled'

// import * as Styled from './ResponsiveGrid.styles'

// put this in ResponsiveGrid.styles.jsx
// It was likely working before, but my computer is trash
const GridContainer = styled.div`
  display: grid;
  column-gap: ${(props) => props.columnGap};
  row-gap: ${(props) => props.rowGap};
  grid-template-columns: repeat(
    ${(props) => props.numberOfMobileColumns || props.numberOfColumns},
    1fr
  );
  width: 100%;
  max-width: ${(props) => props.constraint};
  margin: auto;

  img {
    display: block;
    margin: auto;
  }

  .leshen-link-button-wrapper {
    .leshen-link-button {
      width: 100%;

      span {
        white-space: nowrap;
      }
    }
  }

  @media (min-width: 768px) {
    grid-template-columns: repeat(
      ${(props) => props.numberOfColumns || 5},
      1fr
    );
  }
`

const GridItem = styled(Link)`
  img {
    width: 100%;
    height: auto;
    object-fit: cover;
    display: block;
    justify-items: center;
    margin: auto;
  }
`

const TwoColumn = ({
  images,
  constraint,
  numberOfColumns,
  numberOfMobileColumns,
  children,
  columnGap,
  rowGap,
}) => {
  const widths = {
    small: '600px',
    medium: '800px',
    large: '100%',
  }

  return (
    <GridContainer
      numberOfColumns={numberOfColumns || 2}
      columnGap={columnGap || '16px'}
      rowGap={rowGap || '16px'}
      numberOfMobileColumns={numberOfMobileColumns || numberOfColumns || 2}
      constraint={widths[constraint] || widths.large}
    >
      {/* // map over children and render them inside <GridItem> */}
      {children?.map((child) => (
        <GridItem key={child.key}>{child}</GridItem>
      ))}
      {images?.map((image) => {
        // if image has an href, use a link
        // otherwise, just use an image
        return image?.href ? (
          <GridItem to={image.href} key={image.href}>
            <img src={image.src} alt={image?.alt} />
          </GridItem>
        ) : (
          <img src={image.src} alt={image?.alt} />
        )
      })}
    </GridContainer>
  )
}

export default TwoColumn
