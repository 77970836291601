/* eslint-disable */
import React from 'react'
import { StaticImage } from 'gatsby-plugin-image'
import NarrowBanner from '../components/NarrowBanner'
import att from '../images/brand-logos/white-att.svg'
import amazon from '../images/brand-logos/white-amazon.svg'
import adidas from '../images/brand-logos/white-adidas.svg'
import aetna from '../images/brand-logos/white-aetna.svg'
import google from '../images/brand-logos/white-google.svg'
import centurylink from '../images/brand-logos/white-centurylink.svg'
import dish from '../images/brand-logos/white-dish.svg'
import directv from '../images/brand-logos/white-directv.svg'
import humana from '../images/brand-logos/white-humana.svg'
import microsoft from '../images/brand-logos/white-microsoft.svg'
import tmobile from '../images/brand-logos/white-tmobile.svg'
import patagonia from '../images/brand-logos/white-patagonia.svg'
import quantum from '../images/brand-logos/white-quantum.svg'
import verizon from '../images/brand-logos/white-verizon.svg'
import vivint from '../images/brand-logos/white-vivint.svg'
import xfinity from '../images/brand-logos/white-xfinity.svg'
import HandAndTablet from '../images/devices/hand-and-tablet.png'
import ResponsiveGrid from '../components/GridLayouts/ResponsiveGrid'
import useWindowSize from '@leshen/ui/src/hooks/useWindowSize'
import header from '../components/Header'
import footer from '../components/Footer'

import {
  Billboard,
  Column,
  Columns,
  LinkButton,
  SplitContent,
  Typography,
  VariableContent,
} from '@leshen/gatsby-theme-leshen'
import { Layout } from '@leshen/gatsby-theme-contentful'

const Home = () => {
  const windowSize = useWindowSize()
  const isMobile = windowSize.width < 940
  const brandLogos = [
    { src: att, alt: '' },
    { src: amazon, alt: ' ' },
    { src: adidas, alt: ' ' },
    { src: aetna, alt: ' ' },
    { src: google, alt: ' ' },
    { src: centurylink, alt: '' },
    { src: dish, alt: '' },
    { src: directv, alt: '' },
    { src: humana, alt: '' },
    { src: microsoft, alt: '' },
    { src: tmobile, alt: '' },
    { src: patagonia, alt: '' },
    { src: quantum, alt: '' },
    { src: verizon, alt: '' },
    { src: vivint, alt: '' },
    { src: xfinity, alt: '' },
  ]

  const layoutProps = {
    data: {
      contentfulPage: {
        header: header(),
        footer: footer(),
        seo: {
          title: 'Full-Funnel Marketing & Sales Company | Clearlink',
          description:
            'We are experts at connecting brands with customers that convert. Connect with us to learn more about partnerships or career opportunities.',
          canonical: 'https://clearlink.com/',
          robots: 'follow,index',
        },
        path: '/',
        promoCode: 'SAMPLEPROMO',
        ringPool: 'RINGPOOL123',
        hideStickyCTA: false,
        spanish: false,
        sections: [
          {
            // Add any relevant section data
          },
        ],
      },
      site: {
        siteMetadata: {
          siteURL: 'https://clearlink.com',
          siteName: 'Clearlink',
          alternateName: 'Clearlink',
          removeTrailingSlashes: true,
        },
      },
    },
    titleTemplate: 'Full-Funnel Marketing & Sales Company | Clearlink',
    defaultRobots: 'follow,index',
    main: (
      <>
        <Billboard
          backgroundColor="dark"
          className="content-transparent-desktop"
          alignImageToBottom
          mobileAlignImageToBottom
          variant="full"
          image={
            <img
              src={HandAndTablet}
              alt="a pair of hands holding a tablet against a black background"
            />
          }
          mobileImage={
            <img
              src={HandAndTablet}
              alt="a pair of hands holding a tablet against a black background"
            />
          }
          mainContent={
            <>
              <div
                data-sal="slide-right"
                data-sal-delay="100"
                data-sal-duration="500"
                data-sal-easing="ease"
              >
                <Typography variant="h1">
                  Connect your brand with customers that convert
                  <span className="blue-punctuation-large">.</span>
                </Typography>
                <Typography className="mb-32">
                  Clearlink combines a vast media portfolio with data-driven
                  performance marketing and expert sales teams to connect your
                  brand with consumers and convert them into customers.
                </Typography>
                <LinkButton variant="hero" to="#see-how" color="primary">
                  See how we do it{' '}
                </LinkButton>
              </div>
            </>
          }
        />

        <VariableContent backgroundColor="dark" alignMainContent="center">
          <div
            data-sal="slide-right"
            data-sal-duration="500"
            data-sal-easing="ease"
          >
            <Columns leftAligned={true}>
              <Column className="text-center">
                <Typography variant="h3" className="color-primary">
                  1.5B
                </Typography>
                <Typography variant="body">
                  Site Interactions Per Year
                </Typography>
              </Column>
              <Column className="text-center">
                <Typography variant="h3" className="color-primary">
                  300M
                </Typography>
                <Typography variant="body">Site Visitors Per Year</Typography>
              </Column>
              <Column className="text-center">
                <Typography variant="h3" className="color-primary">
                  29M
                </Typography>
                <Typography variant="body">Leads Generated Per Year</Typography>
              </Column>
              <Column className="text-center">
                <Typography variant="h3" className="color-primary">
                  315K
                </Typography>
                <Typography variant="body">Sales Closed Per Year</Typography>
              </Column>
            </Columns>
          </div>
        </VariableContent>

        <VariableContent id="see-how" style={{ transform: 'translateY(1px)' }}>
          <Typography
            variant="h2"
            className="narrow center-desktop center"
            style={{ marginBottom: '32px' }}
          >
            A wealth of information at users' fingertips
            <span className="blue-punctuation-large">.</span>
          </Typography>
          <Typography
            className="narrow center-desktop center"
            style={{ marginBottom: '32px' }}
          >
            Millions of people rely on our expert content and comparison tools
            to make purchasing decisions with confidence. We provide the world's
            leading brands with access to new, engaged audiences who are ready
            to take meaningful action.
          </Typography>

          <div
            data-sal="slide-up"
            data-sal-duration="500"
            data-sal-easing="ease"
          >
            <div
              data-sal="slide-up"
              data-sal-duration="500"
              data-sal-easing="ease"
            >
              {isMobile ? (
                <StaticImage
                  src="../images/illustrations/our-properties-black-mobile.png"
                  alt=""
                  placeholder="blurred"
                  layout="fullWidth"
                />
              ) : (
                <StaticImage
                  src="../images/illustrations/our-properties-black-desktop.png"
                  alt=""
                  placeholder="blurred"
                  layout="fullWidth"
                />
              )}
            </div>
          </div>
        </VariableContent>

        <VariableContent backgroundColor="primary">
          <Typography
            variant="h2"
            className="narrow center-desktop center"
            style={{ marginBottom: '32px' }}
          >
            Connecting consumers with our partners
            <span className="white-punctuation-large">.</span>
          </Typography>
          <Typography
            variant="body"
            className="narrow center-desktop center"
            style={{ marginBottom: '32px' }}
          >
            We partner with some of the most influential brands in the world to
            offer consumers affordable products and services tailored to their
            needs.
          </Typography>
          <div
            data-sal="slide-up"
            data-sal-delay="300"
            data-sal-duration="500"
            data-sal-easing="ease"
          >
            <ResponsiveGrid
              images={brandLogos}
              numberOfColumns={4}
              numberOfMobileColumns={2}
              constraint="medium"
              columnGap="88px"
            />
          </div>
        </VariableContent>

        <NarrowBanner
          backgroundColor="#0D71CD"
          heading={
            <div
              data-sal="slide-right"
              data-sal-delay="300"
              data-sal-duration="500"
              data-sal-easing="ease"
            >
              <Typography variant="h5" className="s2">
                Engage the right customers at the right time.
              </Typography>
            </div>
          }
          button={
            <LinkButton
              to="/contact"
              color="dark"
              data-sal="slide-left"
              data-sal-delay="300"
              data-sal-duration="500"
              data-sal-easing="ease"
            >
              Become a partner
            </LinkButton>
          }
        />
        <div className="split-content">
          <SplitContent
            className="reverse-desktop no-padding-bottom"
            alignImageToBottom
            image={
              <StaticImage
                src="../images/people/agent-black-and-white.png"
                alt="a woman wears a headset and smiles while raising hands palms up"
                placeholder="blurred"
              />
            }
            mobileImage={
              <StaticImage
                src="../images/people/agent-black-and-white.png"
                alt="a woman wears a headset and smiles while raising hands palms up"
                placeholder="blurred"
                width={250}
                height={250}
              />
            }
            mainContent={
              <div
                data-sal="slide-right"
                data-sal-delay="300"
                data-sal-duration="500"
                data-sal-easing="ease"
              >
                <Typography variant="h2">
                  Convert with confidence
                  <span className="blue-punctuation-large">.</span>
                </Typography>
                <Typography variant="h4" style={{ marginBottom: '26px' }}>
                  Our world-class sales center gets results—closing the deal
                  with expertise and tact to give every customer a satisfying
                  experience.
                </Typography>
              </div>
            }
          />
        </div>
        <VariableContent backgroundColor="dark">
          <div>
            <Typography
              variant="h2"
              className="narrow center-desktop center"
              style={{ marginBottom: '32px' }}
            >
              Build your career with us
              <span className="blue-punctuation-large">.</span>
            </Typography>
          </div>
          <Typography
            className="narrow center-desktop center"
            style={{ marginBottom: '32px' }}
          >
            We strive to provide a culture of inclusivity and acceptance that is
            responsive to every individual’s needs. Our company of caring and
            competent professionals will support you and give you the resources
            you need to be your best self. The values we represent create a
            balanced culture of care and accountability.
          </Typography>
          {isMobile ? (
            <div
              data-sal="slide-up"
              data-sal-delay="300"
              data-sal-duration="500"
              data-sal-easing="ease"
            >
              <StaticImage
                src="../images/illustrations/clear-values-white.png"
                alt=""
                placeholder="blurred"
                layout="fullWidth"
              />
            </div>
          ) : (
            <div
              data-sal="slide-up"
              data-sal-delay="300"
              data-sal-duration="500"
              data-sal-easing="ease"
            >
              <StaticImage
                src="../images/illustrations/clear-values-white.png"
                alt=""
                placeholder="blurred"
                layout="fullWidth"
              />
            </div>
          )}
          <div
            style={{
              backgroundColor: '#000000',
              padding: '24px',
              maxWidth: '500px',
              margin: '48px auto 0',
              textAlign: 'center',
            }}
          >
            <Typography style={{ marginTop: '0' }}>
              Be a part of an organization that’s invested in your success.
            </Typography>
            <LinkButton
              CustomLink=""
              to="/careers"
              color="primary"
              style={{
                display: 'inline-block',
                maxWidth: 'fit-content',
                margin: 'auto',
              }}
            >
              Join our team
            </LinkButton>
          </div>
        </VariableContent>
        <div className="hq-background">
          <VariableContent background alignMainContent="center" id="hq">
            <div
              data-sal="slide-up"
              data-sal-delay="100"
              data-sal-duration="500"
              data-sal-easing="ease"
              className="video-container"
            >
              <video
                width="100%"
                height="auto"
                controls
                className="hq-video"
                poster={
                  isMobile
                    ? 'https://res.cloudinary.com/clearlink/image/upload/v1721172566/Clearlink/Index/welcome-to-hq-play-btn-mobile-6697021a06cc2_aimnwn.webp'
                    : 'https://res.cloudinary.com/clearlink/image/upload/v1721172565/Clearlink/Index/welcome-to-hq-play-btn-desktop-6697021a06cc2_unzb2k.webp'
                }
              >
                <source
                  src="https://res.cloudinary.com/clearlink/video/upload/v1721249632/Clearlink/Index/HQ-video-beta-1_3_qwlvfb.mp4"
                  type="video/mp4"
                />
                Your browser does not support the video tag.
              </video>
            </div>
          </VariableContent>
        </div>
      </>
    ),
  }

  return <Layout {...layoutProps} />
}
export default Home
